import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Hidden, usePopover } from '@fortum/elemental-ui'

import { isExternal, useRouter } from '@/i18n/navigation'
import type { LinkEntry, MainNavigationEntry } from '@/shared/contentful/types'
import { isTypeLink } from '@/shared/contentful/types/contentTypeGuards'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { routes } from '@/shared/routes'

import { MainNavigation } from './MainNavigation'
import { TopNavigation } from './TopNavigation'
import { useBodyScrollLock } from '../NavigationMobile/SliderMenu/utils'
import { useCloseOnPathnameChange } from '../parts/hooks/useCloseOnPathnameChange'
import { ShadowOverlay } from '../parts/ShadowOverlay'

type NavigationDesktopProps = {
  /**
   * Top navigation items
   */
  topNavigation: LinkEntry[]
  /**
   * Main navigation items
   */
  mainNavigation: MainNavigationEntry
  /**
   * Username of the logged in user
   */
  username: string | undefined
  /**
   * Open search callback
   */
  onOpenSearch: VoidFunction
}

/**
 * Component responsible for desktop navigation
 */
export const NavigationDesktop = ({
  topNavigation,
  mainNavigation,
  username,
  onOpenSearch,
}: NavigationDesktopProps) => {
  const router = useRouter()
  const [selectedHeaderItem, setSelectedHeaderItem] = useState<string>()
  const [isShadowOverlay, setIsShadowOverlay] = useState(false)
  const [isTopBarVisible, setIsTopBarVisible] = useState(true)
  const openWebUserLoginButtonPopover = usePopover()
  const [height, setHeight] = useState<string | number>(0)
  const contentRef = useRef<HTMLDivElement>(null)

  useCloseOnPathnameChange(() => setSelectedHeaderItem(undefined))

  // Check window object to prevent errors happening during SSR
  const resizeObserverRef = useRef(
    typeof window !== 'undefined'
      ? new ResizeObserver((entries: ResizeObserverEntry[]) =>
          setHeight(`${entries[0].borderBoxSize[0].blockSize}px`),
        )
      : null,
  )
  const observerTarget = useRef(null)

  useBodyScrollLock(Boolean(selectedHeaderItem))

  const handleHeaderMenuItemClick = (name: string) => {
    const value = name !== selectedHeaderItem ? name : undefined
    setSelectedHeaderItem(value)
  }

  const handleClickShadowOverlay = (e: MouseEvent) => {
    e.stopPropagation()
    openWebUserLoginButtonPopover.handleClose()
    setSelectedHeaderItem(undefined)
  }

  useEffect(() => {
    setIsShadowOverlay(Boolean(selectedHeaderItem))
  }, [selectedHeaderItem])

  useEffect(() => {
    const observerRef = resizeObserverRef.current
    const ref = contentRef.current

    if (ref) {
      observerRef?.observe(ref)
    }

    return () => observerRef?.disconnect()
  }, [])

  /**
   * Hook that set information about TopBar visibility (intersection with viewPort)
   */
  useEffect(() => {
    const currentTarget = observerTarget.current

    const observer = new IntersectionObserver(
      (entries) =>
        entries[0].isIntersecting ? setIsTopBarVisible(true) : setIsTopBarVisible(false),
      { threshold: 1 },
    )

    if (currentTarget) {
      observer.observe(currentTarget)
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget)
      }
    }
  }, [observerTarget])

  const changeTab = (e: string | number) => {
    const item = mainNavigation?.itemsCollection?.items?.find((item) => item?.name === e)

    if (item && isTypeLink(item)) {
      const url = getLinkEntryUrl(item)
      if (url) {
        if (isExternal(url)) {
          location.assign(url)
        } else {
          router.push({ pathname: routes.GENERIC, params: { slug: url } })
        }
      }

      setSelectedHeaderItem(undefined)
    } else {
      handleHeaderMenuItemClick(e as string)
    }
  }

  return (
    <>
      <Hidden below="xl">
        {isShadowOverlay && (
          <ShadowOverlay
            $isOpen={isShadowOverlay}
            $duration={300}
            data-testid="shadow-overlay"
            onClick={handleClickShadowOverlay}
          />
        )}
        <TopNavigation topNavigation={topNavigation} onOpenSearch={onOpenSearch} />
        <div ref={observerTarget} />
      </Hidden>

      <MainNavigation
        isTopBarVisible={isTopBarVisible}
        mainNavigation={mainNavigation}
        selectedHeaderItem={selectedHeaderItem}
        username={username}
        changeTab={changeTab}
        closeMenu={() => setSelectedHeaderItem(undefined)}
        height={height}
        contentRef={contentRef}
        onOpenSearch={onOpenSearch}
      />
    </>
  )
}
